import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "secondsHiddenField", "minutesDisplayField", "secondsDisplayField" ]
  
  connect() {
    this.fillValues()
  }
  
  fillValues() {
    const runtimeSeconds = this.getSecondsHiddenFieldValue()
    
    if (runtimeSeconds > 0) {
      const secondsInMinute = 60
      const seconds = (runtimeSeconds % secondsInMinute)
      const minutes = (runtimeSeconds-seconds) / secondsInMinute

      this.minutesDisplayFieldTarget.value = minutes
      this.secondsDisplayFieldTarget.value = seconds
    }
  }
  
  getSecondsHiddenFieldValue() {
    return parseInt(this.secondsHiddenFieldTarget.value || 0)
  }
  
  getMinutesDisplayFieldValue() {
    return parseInt(this.minutesDisplayFieldTarget.value || 0)
  }
  
  getSecondsDisplayFieldValue() {
    return parseInt(this.secondsDisplayFieldTarget.value || 0)
  }
  
  getSecondsValue() {
    const minutesValue = this.getMinutesDisplayFieldValue()
    const secondsValue = this.getSecondsDisplayFieldValue()

    const fullValueInSeconds = ((minutesValue * 60) + secondsValue)
    return fullValueInSeconds
  }
  
  update() {
    const secondsValue = this.getSecondsValue()
    this.secondsHiddenFieldTarget.value = secondsValue
  }
}